const baseUrl: string = `${
  process.env.REACT_APP_USE_PROXY === 'true'
    ? process.env.REACT_APP_BASE_PROXY_URL
    : process.env.REACT_APP_BASE_URL
}`;

export const API_URL_CONSTANTS = {
  VERSION: '0.0.1',
  BASE_URL: `${baseUrl}MVC/api/`,
  REGISTRATION: 'setparentregistration',
  LOGIN: 'loginchk',
  GET_KID_PROFILE: 'getkidprofile',
  SET_KID_REGISTRATION: 'setkidregistration',
  UPDATE_KID_REGISTRATION: 'setkidprofileupdate',
  GET_AVATAR: 'getavatar',
  GET_PREFERENCES_List: 'getprofilepreferences',
  FORGOT_PASSWORD: 'setforgotpassword',
  VERIFY_OTP: 'verifyotp',
  CHANGE_PASSWORD: 'changepassword',
  SET_RESEND_OTP: 'setresendotp',
  PARENT_PROFILE: 'getparentprofile',
  PARENT_PROFILE_UPDATE: 'setparentprofileupdate',
  MOBILE_UPDATE: 'setmobilenumupdate',
  GET_LANGUAGES: 'getlangauge',
  GET_SECTION_ITEMS: 'getsectionitems',
  GET_CAT_MASTER: 'getcatgmaster',
  GET_SECTION_CAT_ITEMS: 'getsectioncatitems',
  GET_CATG_MASTER: 'getcatgmaster',
  GET_SUB_CATEGORY: 'getsubcategory',
  GET_MAIN_CAT: 'getmaincategory',
  GET_SEARCH: 'getsearch',
  GET_POLICY_NOTES: 'getpolicynotes',
  GET_SOCIAL_LINKS: 'getsociallinks',
  GET_NOTIFICATIONS: 'getnotifications',
  GET_NOTIFICATIONS_COUNT: 'getnotificationscount',
  GET_WATCHED_LIST: 'getwatchedlist',
  GET_FAQ: 'getfaq',
  GET_SETTING_FEEDBACK: 'getsettingfeedback',
  SET_FEEDBACK: 'setfeedback',
  GET_CONTACT_US: 'getcontactus',
  GET_FAVOURITE: 'getfavourite',
  GET_ITEM_DETAILS: 'getitemdetails',
  SET_FAVOURITE: 'setfavourite',
  SET_WATCHED_LIST: 'setwatchedlist',
  SET_PIN: 'setpin',
  SET_CHANGE_PIN: 'setchangepin',
  GET_PREFERENCES: 'getsettings',
  SET_PREFERENCE: 'setpreference',
  TOONS: 'gettoons',
  SCHEDULED_PROGRAMS: 'scheduledprograms',
  GET_PAGE: 'getpage',
};
